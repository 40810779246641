// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import Analytics from "../../blocks/analytics/src/Analytics";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Cfcertificategeneration4 from "../../blocks/cfcertificategeneration4/src/Cfcertificategeneration4";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import Videos from "../../blocks/videos/src/Videos";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import AssessmentTest from "../../blocks/assessmenttest/src/AssessmentTest";
import Download from "../../blocks/download/src/Download";
import DownloadUpload from "../../blocks/download/src/DownloadUpload";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import QuestionBank from "../../blocks/questionbank/src/QuestionBank";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import DocumentOpener from "../../blocks/documentopener/src/DocumentOpener";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Servicespecificsettingsadmin2 from "../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";


const routeMap = {
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Cfcertificategeneration4:{
 component:Cfcertificategeneration4,
path:"/Cfcertificategeneration4"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
Videos:{
 component:Videos,
path:"/Videos"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
AssessmentTest:{
 component:AssessmentTest,
path:"/AssessmentTest"},
Download:{
 component:Download,
path:"/Download"},
DownloadUpload:{
 component:DownloadUpload,
path:"/DownloadUpload"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
QuestionBank:{
 component:QuestionBank,
path:"/QuestionBank"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
DocumentOpener:{
 component:DocumentOpener,
path:"/DocumentOpener"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Servicespecificsettingsadmin2:{
 component:Servicespecificsettingsadmin2,
path:"/Servicespecificsettingsadmin2"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;